<template>
  <v-container id="news" tag="section">
    <!-- =======================================================
                                Overlay
    ============================================================-->
    <v-overlay :value="overlay">
      <v-progress-circular :size="70" :width="7" color="blue" indeterminate></v-progress-circular>
    </v-overlay>

    <!-- =======================================================
                                Dialog
    ============================================================-->
    <div class="text-center">
      <v-dialog v-model="dialog.value" width="500">
        <v-card>
          <v-card-title class="headline red white--text">
            <v-spacer />
            {{dialog.title}}
            <v-spacer />
          </v-card-title>

          <v-card-text class="mt-5">{{dialog.message}}</v-card-text>

          <v-divider></v-divider>

          <v-card-actions class="pa-5">
            <v-btn text @click="dialogClear()">Não</v-btn>
            <v-spacer></v-spacer>
            <v-btn text @click="dialog.action()">Sim</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

     <!-- =======================================================
                                Main
    ============================================================-->
    <v-btn color="green" class="ma-2 white--text" to="/pages/news/create">
      <v-icon left dark>mdi-plus</v-icon>Adicionar
    </v-btn>

    <tables
      :title="title"
      :columns="columns"
      :linesData="linesData"
      @action="action($event)"
      :actionsOptions="actionsOptions"
      :switchParam="true"
      @spotlight="alterSpotlight"
    />

    <v-row class="d-flex" v-if="countPages > 0">
      <v-col md="4" lg="4" xl="4" class="pa-5 d-flex justify-left">
        <v-pagination
          v-model="page"
          :length="countPages"
          @input="handlePaginationPageChange"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import newsService from "../../../../service/newsService";

export default {
  name: "News",
  components: {
    Tables: () => import("../../../../components/cms/Tables")
  },
  data: () => ({
    page: 1,
    countPages: 0,
    title: "Notícias Cadastradas",
    columns: ["Destaque","Nome", "Categoria", "Data da postagem", "Ações"],
    actionsOptions: ["edit", "delete"],
    linesData: [],
    approvedDialog: false,
    overlay: false,
    dialog: {
      title: "",
      message: "",
      type: "",
      id: "",
      value: false,
      action: null
    }
  }),
  methods: {
    action($event) {

      switch ($event.type) {
        case "edit":
          this.edit($event.id);
          break;
        case "delete":
          const data = {
            title: "Excluir notícias",
            message: "Deseja excluir essa notícia?",
            type: "delete",
            id: $event.id,
            action: this.deleteNews
          };
          this.dialogAction(data);
          break;
        default:
          break;
      }
    },
    alterSpotlight(id){
      newsService.alterSpotlight(id).then(res => {
        this.getAll()
      })
    },
    dialogAction(data) {
      this.dialog = Object.assign({}, data);
      this.dialog.value = true;
    },
    dialogClear() {
      this.dialog = {
        title: "",
        message: "",
        type: "",
        id: "",
        value: false,
        action: null
      };
    },
    getAll() {
      this.overlay = true;

      newsService.paginate({}, this.page).then(res => {
        this.overlay = false;

        const templateResponse = res.data;
        this.countPages = templateResponse.countPages;

        this.linesData = templateResponse.data.map(news => {
          return {
            id: news.id,
            id_category: news.id_category,
            spotlight:news.spotlight?true:false,
            lines: [
              {width:40 ,name:news.title},
              {width:20 ,name:news.category},
              {width:15 ,name:news.date}
            ]
          };
        });
      });

    },
    edit(id) {
      this.$router.push({ path: `/pages/news/edit/${id}` });
    },
    deleteNews() {
      newsService.delete(this.dialog.id).then(res => {
        this.getAll();
        this.dialogClear();
      });
    },
    handlePaginationPageChange() {
      this.getAll();
    },
  },
  mounted() {
    this.getAll();
  }
};
</script>

